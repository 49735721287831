'use client'

import { FilterPopup } from '@component/fullPopup/popup'
import { isFunction } from 'lodash-es'
import { useCallback } from 'react'
import useHomeSearchContext from '../context'
import { fetchBuy } from '../fetch'
import { HomeSearchInputNew } from '../input'
import HomeSearchPopup from '../popup'

export default function SearchPopup({
  show = false,
  onClose,
  onFilter,
  fetcher,
  placeholder,
  mobilePlaceholder,
  onSearch,
}) {
  const {
    suburbs,
    surrounding
  } = useHomeSearchContext()
  const searchHandle = useCallback(() => {
    isFunction(onSearch) && onSearch({ suburbs, surrounding })
  }, [onSearch, suburbs, surrounding])

  return (
    <FilterPopup
      show={show}
      title='Search by location'
      onBack={onClose}
      buttonWrapClassName='!p-10'
      fixedButton
      confirmButton={
        <div className='button-primary-out w-full' onClick={searchHandle}>Done</div>
      }
    >
      {
        show && (
          <HomeSearchInputNew
            filterButton
            filterButtonClassName='!p-6 mr-6 border border-primary rounded-sm before:hidden'
            showSearchButton={false}
            onFilter={onFilter}
            autoFocus
            placeholder={placeholder}
            mobilePlaceholder={mobilePlaceholder}
          >
            <HomeSearchPopup
              className='!border-t !border-0 mt-16 -mx-16'
              fetcher={fetcher}
            ></HomeSearchPopup>
          </HomeSearchInputNew>
        )
      }
    </FilterPopup>
  )
}
